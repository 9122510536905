import * as React from "react"
import { Link } from "react-scroll"

const VerticalMenu = () => {

  const linkStyle = `
    vertical-menu
    text-dark-grey
    text-lg text-right
    hover:border-dark-grey
    h-12
    flex
    flex-col
    cursor-pointer
  `

  const linkActive = `
    active
    font-semibold
  `

  return (
    <div className="hidden lg:block z-10">
      <ul className="text-right">
        <li><Link
          activeClass={linkActive}
          className={`${linkStyle} ${linkActive}`}
          to="home"
          spy={true}
          smooth={true}
          duration={800}>
          Home
        </Link></li>
        <li>
        <Link
          activeClass={linkActive}
          className={linkStyle}
          to="about-us"
          spy={true}
          smooth={true}
          duration={800}>
          About Us
        </Link>
        </li>
        <li>
        <Link
          activeClass={linkActive}
          className={linkStyle}
          to="services"
          spy={true}
          smooth={true}
          duration={800}>
          Services
        </Link>
        </li>
        <li>
        <Link
          activeClass={linkActive}
          className={linkStyle}
          to="our-works"
          spy={true}
          smooth={true}
          duration={800}>
          Our Works
        </Link>
        </li>
        {/* <Link
          activeClass={linkActive}
          className={linkStyle}
          to="blog"
          spy={true}
          smooth={true}
          duration={800}>
          Blog
        </Link> */}
        <Link
          activeClass={linkActive}
          className={linkStyle}
          to="contact-us"
          spy={true}
          smooth={true}
          duration={800}>
          Contact Us
        </Link>
      </ul>
    </div>
  )
}

export default VerticalMenu;