import React, {useState, useRef} from 'react'
import photoAids from "../../../images/who-aids.png"
import photoFin from "../../../images/who-fin.png"
import MeetOurTeamFin from './MeetOurTeamFin'
import MeetOurTeamAids from './MeetOurTeamAids'

export const MeetOurTeam = () => {

  const refProfile2 = useRef(null)
  const refProfile1 = useRef(null)
  const refScroller = useRef(null)
  const [profile, setProfile] = useState('Profile1')
  const [selectedProfile, setSelectedProfile] = useState(null)

  const profiles = {
    Aids: {
      name: 'Adrian "Aids" Tumusok',
      photo: photoAids,
      photoAlt: 'Aids',
      title: "President/Software Architect",
      bio: <MeetOurTeamAids/>,
    },
    Fin: {
      name: 'Josephine "Fin" Neric',
      photo: photoFin,
      photoAlt: 'Fin',
      title: "CEO/Project Manager",
      bio: <MeetOurTeamFin/>,
    },
  }

  const getRandomIntInclusive = (min, max)=> {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  }

  // setProfile(getRandomIntInclusive(0, 1) === 1 ? 'profile1' : 'profile2' )

  const [profile1, setProfile1] = useState(profiles.Fin)
  const [profile2, setProfile2] = useState(profiles.Aids)

  const showProfile2 = (e) => {
    let newOffset = refProfile1.current.offsetHeight
    refScroller.current.scroll({
      top: newOffset,
      left: 0,
      behavior: 'smooth'
    })
  }
  const showProfile1 = (e) => {
    refScroller.current.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

  }
  const handleScroll = event => {
    let newOffset = refProfile1.current.offsetHeight - 100
    if (event.currentTarget.scrollTop >= newOffset) {
      if (profile !== 'Profile2') setProfile('Profile2')
    } else {
      if (profile !== 'Profile1') setProfile('Profile1')
    }
  }

  React.useEffect(() => {
    const randomInteger = getRandomIntInclusive(0,3)
    console.log('random', randomInteger)
    let tempprofile1 = profile1
    let tempprofile2 = profile2
    if (randomInteger < 1) {
      setSelectedProfile('Aids')
      tempprofile1 = profiles.Aids
      tempprofile2 = profiles.Fin
    } else {
      setSelectedProfile('Fin')
      tempprofile2 = profiles.Aids
      tempprofile1 = profiles.Fin
    }
    setProfile1(tempprofile1)
    setProfile2(tempprofile2)
  }, [])

  return (
  <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
    <div className="hidden lg:block lg:col-span-1 px-8 w-full mx-auto">
      <div className="lg:relative z-0 w-full min-h-[400px]">
        <div onClick={() => showProfile1()} className={`${profile === 'Profile1' ? 'active' : ''}` + ` cursor-pointer about-team-photo lg:absolute h-[225px] w-[200px] md:w-1/2 md:translate-x-12 lg:translate-x-0 lg:h-[270px] lg:w-[260px] xl:h-[350px] xl:w-[300px] xl:left-10`}>
          <img src={profile1.photo} alt={profile1.photoAlt} />
        </div>
        <div onClick={() => showProfile2()} className={`${profile === 'Profile2' ? 'active' : ''}` + ` cursor-pointer about-team-photo lg:absolute h-[225px] w-[200px] md:w-1/2 md:translate-x-1/2 lg:translate-x-0 lg:h-[270px] lg:w-[260px] xl:h-[350px] xl:w-[300px] inset-0 top-32 left-20 xl:left-32 xl:top-56 2xl:left-52 2xl:top-60`}>
          <img src={profile2.photo} alt={profile2.photoAlt} />
        </div>
      </div>
    </div>
    <div ref={refScroller} className="col-span-2 lg:col-span-1 lg:overflow-y-scroll lg:h-[620px] px-8 flex flex-col items-center" onScroll={handleScroll}>
      <div className="block lg:hidden h-[225px] w-[200px]"><img src={profile1.photo} alt={profile1.photoAlt} /></div>
      <div ref={refProfile1} className="w-full text-left pt-8 pb-3.5">
        <h2 className="uppercase text-dark-grey font-semibold lg:text-2xl">{ profile1.name }</h2>
        <h3 className="text-dark-grey lg:text-lg">{ profile1.title}</h3>
        <div className="about-team-bio">
          { profile1.bio }
        </div>
      </div>
      <div className="block lg:hidden h-[225px] w-[200px] mt-10"><img src={ profile2.photo } alt={ profile2.photoAlt } /></div>
        <div id="meet-fin" ref={refProfile2} className="w-full text-left pt-8 pb-3.5">
        <h2 className="uppercase text-dark-grey font-semibold lg:text-2xl pt-2.5">{ profile2.name }</h2>
        <h3 className="text-dark-grey lg:text-lg"> { profile2.title } </h3>
        </div>
        
        <div className="about-team-bio">
          { profile2.bio }
        </div>
        
      </div>
  </div>
  )
}