import * as React from 'react'
import FeaturedWorkCard from './FeaturedWorkCard'
import bgSmartpro from '../../../images/bg-smartpro.png'
import bgQOI from '../../../images/bg-qoi.png'
import bgActionera from '../../../images/bg-actionera.png'
import MobileBGActionera from '../../../images/mobile-work-actionera.png'
import MobileBGQOI from '../../../images/mobile-work-qoi.png'
import MobileBGSmartpro from '../../../images/mobile-work-smartpro.png'

const FeaturedWorks = () => {


  return (
    <div className="pb-20">
      <FeaturedWorkCard label="SMART-PRO CLEANERS" bg={bgSmartpro} mobileBG={MobileBGSmartpro}link="/work-smartpro" />
      <FeaturedWorkCard label="QOI SYSTEMS INTEGRATION" bg={bgQOI} mobileBG={MobileBGQOI} link="/work-qoi" />
      <FeaturedWorkCard label="ACTIONERA" bg={bgActionera} mobileBG={MobileBGActionera} link="/work-actionera" />

      {/* <div className="pt-24">
        <button className="mx-auto w-60 lg:w-80 rounded-full px-2 py-2 lg:px-4 lg:py-4 bg-[#909090] text-white uppercase flex justify-center items-center button-default">View all our works</button>
      </div> */}
    </div>
  )
}

export default FeaturedWorks