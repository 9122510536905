import React from 'react'
import reason1 from '../../../images/reason-1-unique.png'
import reason2 from '../../../images/reason-2-cost.png'
import reason3 from '../../../images/reason-3-scalable.png'
import reason4 from '../../../images/reason-4-hands.png'
import reason5 from '../../../images/reason-5-experts.png'
import reason6 from '../../../images/reason-6-world.png'

const reasons = [
  {
    reason: "Unique and Customizable",
    image: reason1
  },
  {
    reason: "Cost-efficient",
    image: reason2
  },
  {
    reason: "Scalable - for future proofing",
    image: reason3
  },
  {
    reason: "Hands-on, agile, and flexible",
    image: reason4
  },
  {
    reason: "Experts in the field",
    image: reason5
  },
  {
    reason: "World-class talent without hefty price tag",
    image: reason6
  }

]




export const Partner = () => {
  const reasonsList = reasons.map((reason, index) => {   
    return (    
    <div className="flex flex-col items-center justify-start" key={index}>
      <div className="h-[100px] w-[120px] lg:w-[150px] lg:h-[120px]">
        <img src={reason.image} alt={reason.reason}/>
      </div>
      <div className="py-4 lg:p-8 ">
      <h3 className="w-48 text-center text-[#707070] px-8 text-sm lg:text-base">{reason.reason}</h3>
      </div>
    </div>
    )
  })
  return (
  <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 px-12">
    {reasonsList}
  </div>
  )
}