import React from 'react';

export default function MeetOurTeamAids() {
  return (
    <>
      <p>
      Adrian founded Tech Tank LLC in the US in 2017 and co-founded TechTank Canada Inc. in Canada the year after on the back of his over 20-year experience. He has developed diverse web-based applications and systems for various US-based clients since 2005. An experienced Ruby developer and AGILE practitioner, Adrian has worked on the development and automated deployment in various server platforms. He also has 10+ years of experience as an IT Head in one of the Philippines' leading engineering firms.
      </p>
      <p>
        A remarkable coding craftsman with strong analytical and problem-solving skills, Aids' ability to translate in layman's terms any back-end concerns and updates to his team and clients make him very easy to work with. His patience and flexibility as well as his solid work ethic is also notable to anyone he has worked with.
      </p>
      <p>
        When he's not working, he enjoys playing video games, travelling, and spending time with his kids. Most importantly, Aids is a die-hard Canucks fan.
      </p>
    </>
  )
}