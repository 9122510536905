import React from "react"
import image from '../../../images/who-we-are-graphic.png'

export const OurMission = () => (
  <div className="flex flex-col-reverse lg:flex-col">
    <div className="px-8">
      <img alt="" src={image}/>
    </div>
    <div className="">
      <p className="text-left lg:text-center text-dark-grey py-4 px-8 lg:px-28"> We enable entrepreneurs through creativity, strategy, technology, and innovation so they can effectively and cost-efficiently market enriching products, services,and experiences. </p>
    </div>
  </div>
)

