import React, {useState} from "react"
import Layout from "../components/layout"
import VerticalMenu from "../components/Header/VerticalMenu"
import Tab from "../components/Home/About/AboutTab"
import ContactForm from "../components/Home/Contact/ContactForm"
import OurServices from "../components/Home/OurServices/OurServices"
import FeaturedWorks from "../components/Home/FeaturedWorks/FeaturedWorks"
import { Link } from "react-scroll"
import Logo from '../images/techtank-logo@2x.png'
import mainImage from '../images/home-main-image.png'
import cloud1 from '../images/home-cloud-1.png'
import cloud2 from '../images/home-cloud-2.png'
import cloud3 from '../images/home-cloud-3.png'
import iconDev from '../images/home-icon-dev.png'
import iconGraphic from '../images/home-icon-graphic.png'
import iconStaff from '../images/home-icon-staff.png'
import iconSocial from '../images/home-icon-social.png'
import iconPie from '../images/home-icon-pie.png'
import star1 from '../images/home-star-1.png'
import star2 from '../images/home-star-2.png'
import star3 from '../images/home-star-3.png'



const IndexPage = () => {
  
  const [animationDone, setAnimationDone] = useState(false)
  const [mainAnimationDone, setMainAnimationDone] = useState(false)
  
  const doSomething = (e) => {
    setAnimationDone(true)
  }
  const startMainBounce = (e) => {
    setMainAnimationDone(true)
  }

  return (
    <div className="home">
      <Layout>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
        <section id="home" className="relative z-0 pt-20 lg:pt-0">
          <div className="container mx-auto flex flex-col justify-between text-left lg:px-4 min-h-screen">
            <div className="flex flex-col">
              <div className="hidden lg:flex justify-between pt-16 lg:pt-16">
                <div className="flex items-center md:items-start">
                  <p className="hidden lg:block">
                    <img className="top-logo" src={Logo} alt="TechTank"/>
                  </p>
                </div>
                <VerticalMenu />
              </div>
              <div>
                <h1 className="home-headline uppercase lg:font-semibold py-6 px-6 text-4xl md:text-7xl w-full mb-9 pt-4 lg:pt-5 lg:hidden">
                  We are your secret weapon.
                </h1>
                {/* <div className="lg:hidden">
                  <img alt="" src={mobileMainImage}/>
                </div> */}
                <div className="mobile-canvas block relative lg:hidden">
                  <div className={`${mainAnimationDone ? 'main-image-bounce' : 'main-image-animation' } main-image absolute top-[11%] left-[-30%] z-0`} onAnimationEnd={startMainBounce}>
                    <img alt="" src={mainImage} style={{height: 308, width: 'auto'}}/>
                  </div>
                  {/* icons */}
                  <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute top-[80%] left-[30%] z-0`} onAnimationEnd={doSomething}>
                    <img alt="" src={iconDev} style={{height: 200/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute bottom-[15%] right-[5%] z-0`}>
                    <img alt="" src={iconGraphic} style={{height: 150/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute top-[20%] right-[1%] z-0`}>
                    <img alt="" src={iconStaff} style={{height: 80/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeIn' } icon-image absolute top-[2%] left-[45%] z-0`}>
                    <img alt="" src={iconSocial} style={{height: 120/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeIn' } icon-image absolute top-[46%] left-[11%]   opacity-[0.5] z-0`}>
                    <img alt="" src={iconPie} style={{height: 120/2, width: 'auto'}}/>
                  </div>


                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } cloud-image absolute bottom-[5%] right-[1%] z-0`} >
                    <img alt="" src={cloud1} style={{height: 216/4, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' }  cloud-image absolute top-[5%] left-[15%] animate_faster  z-0`}>
                    <img alt="" src={cloud2} style={{height: 34/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeIn animate_faster' } cloud-image absolute bottom-[2%] left-[5%] z-0`}>
                    <img alt="" src={cloud3} style={{height: 80/2, width: 'auto'}}/>
                  </div>

                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } star-image absolute top-[10%] right-[15%] z-0`}>
                    <img alt="" src={star1} style={{height: 82/2, width: 'auto'}}/>
                  </div>
                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeIn animate_faster' } star-image absolute top-[31%] left-[5%] z-0`}>
                    <img alt="" src={star2} style={{height: 40/2, width: 'auto'}}/>
                  </div>          
                  <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } star-image absolute bottom-[25%] left-[50%] z-0`}>
                    <img alt="" src={star3} style={{height: 40/2, width: 'auto'}}/>
                  </div>

                </div>
              </div>
            </div>
            <div className="pb-4 lg:pb-36 z-10">
              <h1 className="home-headline uppercase font-semibold text-7xl xl:text-8xl w-5/6 mb-9 pt-10 hidden lg:block">
                <span className="heading">We are your <br/> secret <span className="home-headline-glow">weapon</span></span>.
              </h1>
              <p className="px-6 lg:px-0 my-9 max-w-[415px] pr-8 text-[#707070]">
                From consultancy to branding, to print or digital ads, to websites and social media management, whether you're a solopreneur who wants to jumpstart a business online or a small to medium company who simply needs help managing your social media pages, Tech Tank is here for you.
              </p>
              <Link
                to="contact-us"
                spy={true}
                smooth={true}
                duration={800}>
                <button className="rounded-full text-white uppercase hidden lg:block button-default button-message-us">Message us</button>
              </Link>
            </div>
          </div>
        
        <div className="canvas hidden lg:block">
          <div className={`${mainAnimationDone ? 'main-image-bounce' : 'main-image-animation' } main-image absolute top-[20%] left-[35%] z-0`} onAnimationEnd={startMainBounce}>
            <img alt="" src={mainImage} style={{height: 500, width: 'auto'}}/>
          </div>
          {/* icons */}
          <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute bottom-[18%] right-[30%] z-0`} onAnimationEnd={doSomething}>
            <img alt="" src={iconDev} style={{height: 200, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute bottom-[25%] right-[13%] z-0`}>
            <img alt="" src={iconGraphic} style={{height: 150, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeInRight' } icon-image absolute bottom-[45%] right-[11%] z-0`}>
            <img alt="" src={iconStaff} style={{height: 80, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeIn' } icon-image absolute top-[10%] left-[40%] z-0`}>
            <img alt="" src={iconSocial} style={{height: 120, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'icon-bounce' : 'animate__animated animate__fadeIn' } icon-image absolute top-[36%] left-[47%]   opacity-[0.5] z-0`}>
            <img alt="" src={iconPie} style={{height: 120, width: 'auto'}}/>
          </div>


          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } cloud-image absolute bottom-[10%] right-[5%] z-0`} >
            <img alt="" src={cloud1} style={{height: 216, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' }  cloud-image absolute top-[5%] right-[35%] animate_faster  z-0`}>
            <img alt="" src={cloud2} style={{height: 34, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeIn animate_faster' } cloud-image absolute bottom-[18%] left-[45%] z-0`}>
            <img alt="" src={cloud3} style={{height: 80, width: 'auto'}}/>
          </div>

          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } star-image absolute top-[10%] right-[25%] z-0`}>
            <img alt="" src={star1} style={{height: 82, width: 'auto'}}/>
          </div>
          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeIn animate_faster' } star-image absolute top-[35%] left-[45%] z-0`}>
            <img alt="" src={star2} style={{height: 40, width: 'auto'}}/>
          </div>          
          <div className={`${animationDone ? 'cloud-bounce' : 'animate__animated animate__fadeInRight animate_faster' } star-image absolute bottom-[5%] right-[30%] z-0`}>
            <img alt="" src={star3} style={{height: 40, width: 'auto'}}/>
          </div>

        </div>

        </section>
        <section id="about-us" className="min-h-screen flex">
          <div className="container mx-auto flex flex-col text-left py-24 lg:py-36 px-0 lg:px-4">
            <Tab/>
          </div>
        </section>
        <section id="services" className="min-h-screen flex">
          <div className="w-full flex flex-col py-24 lg:py-36 justify-center">
            <OurServices/>
          </div>
        </section>
        <section id="our-works" className="min-h-screen flex flex-col">
          <div className="container mx-auto flex flex-col text-left pt-24 lg:pt-36 pb-9 px-6 lg:px-4">
            <div>
              <h1 className="uppercase lg:font-semibold text-4xl md:text-7xl">
                <span className="heading">Featured Works</span>
              </h1>
            </div>
          </div>
          <FeaturedWorks />
        </section>
        {/* <section id="blog" className="min-h-screen flex">
          <div className="container mx-auto text-center m-auto">
            blog
          </div>
        </section> */}
        <ContactForm />
      </Layout>
    </div>
  )
}

export default IndexPage
