import React from 'react';

export default function MeetOurTeamFin() {
  return (
    <>
      <p>
        Josephine co-founded TechTank Canada Inc with Adrian in 2018. Before that she was an experienced Group Account Director for a multi-awarded international ad agency in Manila. She has over 15 years experience spearheading the launch and implementation of advertising campaigns and digital projects for different brands and companies covering various categories such as tech, pharmaceuticals, health care, retail, telecommunications, real estate, pay TV, food, beverage, and personal care.
      </p>
      <p>
        Graduating cum laude with a degree in Communication Arts, her training included strategy development hand-in-hand with account management. She believes that Accounts people are not mere messengers but are brand navigators as well as creative advocates. Her dependability, attention to detail, hands-on personality, and genuine interest and loyalty to her brands make her the go-to of her clients.
      </p>
      <p>
        When not busy at work, she likes spending time with her kids, travelling, shopping, and watching movies and TV series.
      </p>
    </>
  )
}