import * as React from 'react'

const OurServicesCard = ({ itemId, itemTitle, itemDescription, itemImage, active }) => {
  return (
    <div className={`${active && "active"} scroll-item flex-none w-fit h-fit justify-center items-center px-2 lg:px-4`}>
      <div className="service-card-container flex justify-center shrink-0 relative w-[300px] h-[420px] lg:w-[32rem] lg:h-[34em] ">
        <div className="service-card p-6 m-4 shadow-lg bg-white flex flex-col items-stretch justify-end">
          <img src={itemImage} alt={itemTitle} className=""/>
          <h5 className="text-[#707070] text-xl lg:text-2xl leading-tight font-medium mb-2">{itemTitle}</h5>
          <p className="text-[#707070] text-base mb-4">
            {itemId}
            {itemDescription}
          </p>
        </div>
      </div>
    </div>
  )
}

export default OurServicesCard

// 2xl:ml-[10vw]
// lg:w-[32rem] lg:h-[38.875rem] 