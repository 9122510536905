import React from 'react'
import client1 from '../../../images/client-actionera.png'
import client2 from '../../../images/client-qoi.png'
import client3 from '../../../images/client-citident.png'
import client4 from '../../../images/client-smartpro.png'
import client5 from '../../../images/client-viapath.png'

export const OurClients = () => (
  <div className="our-clients flex items-center justify-center h-full">
    <div className="flex flex-wrap items-center justify-center px-12 lg:px-4 py-4 w-full">
    <div className="h-[40px] lg:h-[80px] w-full lg:w-[30%] text-center items-center justify-center m-8 py-4">
    <img src={client1} alt="actionera"/>
    </div>
    <div className="h-[40px] lg:h-[80px] w-full lg:w-[30%] text-center items-center justify-center m-8 py-4">
    <img src={client2} alt="qoi"/>
    </div>
    <div className="h-[40px] lg:h-[80px] w-full lg:w-[30%] text-center items-center justify-center m-8 py-4">
    <img src={client3} alt="citident"/>
    </div>
    <div className="h-[40px] lg:h-[80px] w-full lg:w-[30%] text-center items-center justify-center m-8 py-4">
    <img src={client4} alt="smartpro"/>
    </div>
    <div className="h-[40px] lg:h-[80px] w-full lg:w-[30%] text-center items-center justify-center m-8 py-4">
    <img src={client5} alt="viapath"/>
    </div>
  </div>
  </div>
)