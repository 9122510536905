import * as React from 'react'
import { FaChevronRight } from 'react-icons/fa';
import { FaChevronLeft } from 'react-icons/fa';

import { useRef } from 'react'
import { useDraggable } from "react-use-draggable-scroll"
// import { useScrollPosition } from '../../hooks/useScrollPosition'
import OurServicesCard from './OurServicesCard';
import service1 from '../../../images/service-1.png'
import service2 from '../../../images/service-2.png'
import service3 from '../../../images/service-3.png'
import service4 from '../../../images/service-4.png'
import service5 from '../../../images/service-5.png'

const OurServices = () => {
  const [current, setCurrent] = React.useState(0)
  const [atStart, setAtStart] = React.useState(true)
  const [atEnd, setAtEnd] = React.useState(false)
  const containerRef = useRef()
  const { events } = useDraggable(containerRef, {
    applyRubberBandEffect: false
  });

  const services = [
    {
      service: "Website, App, and Software Engineering",
      description: "From concept to UX/UI to front-end design to coding to prototyping to testing, we’ll do it",
      image: service3,
      inView: false,
    },
    {
      service: "Brand and Graphic Design Services",
      description: "From branding to web and app interface to print ads and flyers, we’re designed to do that too",
      image: service2,
      inView: false,
    },
    {
      service: "Advertising and IT Consultancy",
      description: "Above-the-line, below-the-line, or online, we’ll have people form a line for your product or service",
      image: service1,
      inView: false,
    },
    {
      service: "Social Media Services",
      description: "Maximize your reach and engage with your target across different social media platforms",
      image: service4,
      inView: false,
    },
    {
      service: "Staff Augmentation",
      description: "Increase your productivity and efficiency without increasing your manpower overhead",
      image: service5,
      inView: false,
    }   
  ]

  const serviceItems = services.map((service, index) =>
    <OurServicesCard key={index} itemTitle={service.service} itemDescription={service.description} itemImage={service.image} inView={service.inView} 
    active={ current === index ? true : false } />
  )

  const handleNext = (e) => {
    console.log(e)
    const cardLength = (containerRef.current.scrollWidth / services.length)

    let nextCurrent = current + 1
    const newScrollLeft = (nextCurrent * cardLength) - 20
    // containerRef.current.scrollLeft = newScrollLeft


    containerRef.current.scroll({
      top: 0,
      left: newScrollLeft,
      behavior: 'smooth'
    })
  }

  const handlePrev = (e) => {
    console.log(e)
    const cardLength = (containerRef.current.scrollWidth / services.length)

    let nextCurrent = current - 1
    if (nextCurrent < 0) {
      nextCurrent = 0
    }
    let newScrollLeft = (nextCurrent * cardLength)
    if (nextCurrent === 0) {
      newScrollLeft = 0
    }
    if (newScrollLeft >= containerRef.current.scrollLeft) {
      newScrollLeft = ((nextCurrent - 1) * cardLength)
    }
    // console.log(current, newScrollLeft, containerRef.current.scrollLeft)

    containerRef.current.scroll({
      top: 0,
      left: newScrollLeft,
      behavior: 'smooth'
    })
  }

  const onScroll = (e) => {
    const cardLength = e.target.scrollWidth / services.length
    const isMobile = Math.round( cardLength / e.target.clientWidth )
    const maxScrollLeft = e.target.scrollWidth - e.target.clientWidth

    let newCurrent = 0
    if ( isMobile === 1) {
      newCurrent = Math.floor( e.target.scrollLeft / ( cardLength * 0.9)  )
    } else {
      newCurrent = Math.floor( e.target.scrollLeft / ( cardLength * 0.6)  )
    }
    if (newCurrent > (services.length - 1)) {
      newCurrent = services.length - 1
    }
    setCurrent( newCurrent )
    if (e.target.scrollLeft > 0) {
      setAtStart(false)
    } else {
      setAtStart(true)
    }
    if (e.target.scrollLeft === maxScrollLeft) {
      setAtEnd(true)
    } else {
      setAtEnd(false)
    }
    // console.log(e.target.scrollLeft,  maxScrollLeft)
  }  

  React.useEffect(() => {
    // const containerRef.current = document.getElementById('scroll')
    containerRef.current.addEventListener("scroll", onScroll);
  }, [])  

  return (
    <>
      <div className="relative w-full px-6 lg:px-4">
        <div className="container mx-auto">
          <div className="flex flex-row items-center justify-between">
            <h1 className="block lg:hidden uppercase lg:font-semibold w-full text-4xl md:text-7xl">
              <span className="heading">Our Services</span>
            </h1>

            <div className="services-navigation hidden lg:block">
              <button className={`${!atStart && 'active'} button-arrow rounded-full p-2 mx-1`} onClick={handlePrev}>
                <FaChevronLeft size={24} className="text-white" />
              </button>
              <button className={`${!atEnd && 'active'} button-arrow rounded-full p-2 mx-1`} onClick={handleNext}>
                <FaChevronRight size={24} className="text-white" />
              </button>
            </div>

            <h1 className="hidden lg:block uppercase lg:font-semibold text-right  text-4xl md:text-7xl">
              <span className="heading">Our Services</span>
            </h1>
          </div>
        </div>
      </div>

      <div className="pt-11">
        <div id="scroll" className={`overflow-x-scroll flex pl-0 pr-8 lg:px-8 `} ref={containerRef} {...events}>
        {serviceItems}
        </div>
      </div>

    </>
  )
}

export default OurServices