import React, { useRef, useState } from 'react'
// import { MeetOurTeam } from './MeetOurTeam';
import { MeetOurTeam } from './MeetOurTeamRandom';
import { OurClients } from './OurClients';
import { OurMission } from './OurMission';
import { Partner } from './Partner';

const Tab = () => {
  const appRef = useRef(null)

  const [tabOpen, setTabOpen] = useState({
    id: 1,
    class: 'block'
  });

  const handleClick = (number) => {
    setTabOpen({
      ...tabOpen,
      id: number
    })

    appRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  const activeTab = `about-active-tab`
  const tabItem = `
    cursor-pointer
    text-[#707070] uppercase
    py-8 px-12 lg:px-14 lg:py-12
  `

  const accordionContentWrapper = `
    py-8
    justify-center items-center
    text-center
  `

  const activeAccordionWrapper = `
    block lg:hidden
  `

  const activeTabWrapper = `
    hidden lg:block
  `

  return (
    <div>
      <div ref={appRef}>
        <h1 className="heading uppercase lg:font-semibold  text-4xl md:text-7xl px-6">
          Who we are
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row w-full pt-6">
      <div className="flex flex-col lg:w-1/3 divide-y divide-slate-700 divide-gradient">
        <button onClick={() => handleClick(1)} className={`${tabItem} ${tabOpen.id === 1 && `${activeTab}`}`}>
          Our mission
        </button>
        <div className={`${accordionContentWrapper} ${tabOpen.id === 1 ? `${activeAccordionWrapper}` : 'hidden'}`}>
          <OurMission />
        </div>

        <button onClick={() => handleClick(2)} className={`${tabItem} ${tabOpen.id === 2 && `${activeTab}`}`}>
          Meet our team
        </button>
        <div className={`${accordionContentWrapper} ${tabOpen.id === 2 ? `${activeAccordionWrapper}` : 'hidden'}`}>
          <MeetOurTeam />
        </div>

        <button onClick={() => handleClick(3)} className={`${tabItem} ${tabOpen.id === 3 && `${activeTab}`}`}>
          Why partner with us?
        </button>
        <div className={`${accordionContentWrapper} ${tabOpen.id === 3 ? `${activeAccordionWrapper}` : 'hidden'}`}>
          <Partner />
        </div>

        <button onClick={() => handleClick(4)} className={`${tabItem} ${tabOpen.id === 4 && `${activeTab}`}`}>
          Our Clients
        </button>
        <div className={`${accordionContentWrapper} ${tabOpen.id === 4 ? `${activeAccordionWrapper}` : 'hidden'}`}>
          <OurClients />
        </div>
        
      </div>
      <div className={`w-full 2xl:px-8 ${tabOpen.id === 1 ? `${activeTabWrapper}` : 'hidden'}`}>
        <OurMission />
      </div>
      <div className={`w-full py-8 ${tabOpen.id === 2 ? `${activeTabWrapper}` : 'hidden'}`}>
        <MeetOurTeam />
      </div>
      <div className={`w-full p-8 ${tabOpen.id === 3 ? `${activeTabWrapper}` : 'hidden'}`}>
        <Partner />
      </div>
      <div className={`w-full px-8 ${tabOpen.id === 4 ? `${activeTabWrapper}` : 'hidden'}`}>
        <OurClients />
      </div>
    </div>
    </div>
  )
}

export default Tab;