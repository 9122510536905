import * as React from 'react'
import { Link } from 'gatsby'

const FeaturedWorkCard = ({ label, bg, mobileBG, link }) => {
  // const bgClass = "bg-[url('" + bg + "')] block featured-work-card"
  const bgClass = "block featured-work-card"
  return (
    <Link to={link} className={bgClass} style={{background: 'url(' + bg + ') top center'}}>
      <div className="mobile-bg-wrap  w-full flex flex-col justify-center items-center h-[450px] py-4 px-12 my-9" style={{background: 'url(' + mobileBG + ' top)'}}>
        <div className="our-works-label ">
          {label}
        </div>
        {/* <span className="mx-auto mt-8 w-60 lg:w-80 rounded-full px-2 py-2 lg:px-4 lg:py-4 bg-[#909090] text-white uppercase flex justify-center items-center button-default">
        View Work
        </span> */} 
      </div>
    </Link>
  )
}

export default FeaturedWorkCard